@import url('https://fonts.googleapis.com/css2?family=Titillium+Web');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

html.with-featherlight {
    overflow:hidden;
}

* {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}